@include title {
	color: $black;
	font-family: $primary-font-family;

	&--secondary {
		font-family: $secondary-font-family;
	}

	&--main {
		color: rgba($black, 0.05);
		font-size: 144px;
		font-family: $secondary-font-family;
		font-weight: normal;
		text-align: center;
		position: relative;

		span {
			font-size: 32px;
			color: $black;
			font-family: $primary-font-family;
			font-weight: bold;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			padding-top: 20px;
		}
	}

	&--spacing {
		margin-top: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
	}

	&--spacing-top {
		margin-top: $block-spacing * 2;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--main {
			font-size: 80px;
			overflow: hidden;

			span {
				padding-top: 10px;
				font-size: 18px;
			}
		}

		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}

		&--align-center-tablet {
			text-align: center;
		}
	}

	@include responsive(('mobile')) {
		&--main {
			font-size: 60px;

			span {
				padding-top: 10px;
				font-size: 18px;
			}
		}
	}
}
