@include media {
	&__image {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&--radius {
			border-radius: 50px;
		}
	}

	&__buttons {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		margin-top: $block-spacing * 3;

		.button {
			margin: $block-spacing;
			min-width: 170px;
		}
	}

	&__caption {
		width: 100%;

		&--dark {
			color: $black;
		}

		&--light {
			color: $white;
		}

		&--hero-home {
			background-color: $background-color;
			width: 45%;
			top: 0;
			left: auto;
			right: 0;
			bottom: 0;
			transform: none;
			padding: 0 6%;
			padding-bottom: 83px;
		}
	}

	&__name {
		font-size: 14px;
		letter-spacing: 1.4px;
		margin-bottom: $block-spacing;
		font-weight: 500;
	}

	&__title {
		font-size: 40px;
		font-family: $secondary-font-family;
		margin-bottom: $block-spacing * 3;
		line-height: 1em;
		color: inherit;

		&--h2 {
			font-size: 44px;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 1.5em;
		color: inherit;
	}

	&--hero-home {
		background-position: center right;
		height: 750px;

		.media__image {
			height: 100%;
			width: 55%;
		}

		.carousel {
			height: 100%;
		}

		.carousel__slide {
			padding: 0;
		}

		.media__controls {
			width: 136px;
			position: relative;
			bottom: 172px;
			left: calc(55% - 136px - 24px);
			justify-content: space-between;
		}

		.media__caption .carousel {
			height: auto;
		}
	}

	&--home {
		border: 1px solid $background-color-2;
		border-radius: 50px;
		position: relative;
		min-height: 423px;
		margin: 60px 0;
		display: flex;
		align-items: stretch;

		.media__caption {
			position: unset;
			top: auto;
			left: auto;
			transform: none;
			width: 61.7%;
			padding: $block-spacing * 2 8.3%;

			.media__buttons .button {
				min-width: 220px;
			}
		}

		.media__image {
			border-radius: 50px;
			width: 30%;
			position: absolute;
			right: 8.3%;
			top: 50%;
			transform: translateY(-50%);

			&::after {
				display: block;
				content: ' ';
				padding-bottom: 120%;
			}
		}
	}

	&--hero-article {
		height: 400px;

		.media__caption {
			padding-bottom: 59px;
		}
	}

	&--hero-news {
		height: 750px;

		.media__caption {
			padding-bottom: 59px;
		}

		.grid {
			height: 100%;
			justify-content: flex-end;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--hero-home {
			height: auto;

			.media__image {
				width: 100%;
				height: 330px;
			}

			.media__controls {
				display: flex;
				margin: 0 auto;
				left: 0;
				justify-content: space-between;
				position: absolute;
				top: calc(330px - 32px);
				width: 100%;
				height: 64px;
				padding: 0 $block-spacing * 3;
			}

			.carousel {
				height: auto;
			}
		}

		.media__caption--hero-home {
			width: 100%;
			position: static;
			top: auto;
			right: auto;
			bottom: auto;
			padding: $block-spacing * 4 $horizontal-spacing-mobile $block-spacing * 10;
		}

		&__title {
			font-size: 24px;

			&--h2 {
				font-size: 32px;
			}
		}

		&--home {
			min-height: unset;
			padding-top: 324px;

			.media__image {
				top: -60px;
				right: auto;
				bottom: auto;
				left: 50%;
				transform: translateX(-50%);
				width: 320px;
				max-width: calc(100% - #{$block-spacing * 4});
			}

			.media__caption {
				width: 100%;
				padding: $block-spacing * 2;
			}

			.media__text {
				padding: 0 8.3%;
			}
		}

		&--hero-article {
			height: 400px;

			.media__caption {
				padding-bottom: 24px;
			}
		}
	}

	@include responsive(('mobile')) {
		&--home {
			.media__buttons .button {
				width: 100%;
			}

			.media__text {
				padding: 0;
			}
		}
	}
}
