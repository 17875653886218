@include number {
	flex-flow: nowrap;

	&__button {
		&:hover {
			color: $white;
			background-color: $brand-color;
		}
	}

	&--product-count {
		width: 50px;
		height: 50px;
		border: 1px solid $border-color;
		color: $black;
		font-size: 16px;
		text-align: center;
		border-radius: 0;
	}

	&--disabled {
		pointer-events: none;
		opacity: 0.5;
	}
}
