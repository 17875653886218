@include filters {
	padding: $block-spacing;
	padding-right: $block-spacing * 3;
	padding-bottom: 54px;
	color: $black;
	position: relative;
	width: 25% !important;

	&__container {
		@extend %flex-row;

		padding: $block-spacing 0;

		&--last {
			padding-bottom: $block-spacing * 2;
		}
	}

	&__select {
		@extend %select;

		color: $black;
		font-size: 14px;
		padding: 14px $block-spacing * 2;
		border: 1px solid $border-color;
		border-radius: 50px;
		font-family: $primary-font-family;
		flex-grow: 1;
		background: url('#{$image-path}/icons/angle-down.svg') no-repeat center right 8px;
		background-size: 8px;
		background-color: $white;

		&--first {
			width: calc(50% - #{$block-spacing});
			margin-right: $block-spacing;
			flex-grow: 0;
		}

		&:hover {
			border-color: $brand-color;
		}
	}

	&__delete {
		@extend %button;

		font-size: 18px;
		padding: 0 $block-spacing * 2;

		&:hover {
			color: $brand-color;
		}
	}

	&__item {
		padding: $block-spacing 0;
		font-size: 16px;
		font-family: $secondary-font-family;

		&:hover {
			color: inherit;
		}

		&--heading {
			border-top: 1px solid $border-color;
			padding: $block-spacing * 3 0;
			font-size: 18px;
		}

		&--no-border {
			border: 0;
		}

		&--active {
			color: $active-color;
		}

		a {
			&:hover {
				color: $highlight-color;
			}
		}
	}

	&__subitem {
		@extend %paragraph;

		width: 100%;
		font-family: $primary-font-family;
		padding: $block-spacing 0;
		font-size: 14px;

		&--sub {
			margin-left: $block-spacing * 2;
		}

		&--active {
			color: $active-color;
		}
	}

	strong {
		font-weight: bold;
	}

	&__toggle {
		display: none;
	}

	&__mobile-close {
		display: none;
	}

	&__mobile-overlay {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100% !important;
		padding: $vertical-spacing-mobile $block-spacing;

		&__mobile {
			position: fixed;
			top: 78px;
			right: 0;
			left: 0;
			bottom: 0;
			overflow-y: auto;
			background: $white;
			border-radius: 50px 0 0;
			padding: 0 $block-spacing * 4 $block-spacing * 4;
			z-index: 10;

			+ .filters__mobile-overlay {
				display: block;
				background-color: $black;
				position: fixed;
				opacity: 0.2;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				z-index: 9;
			}

			&--hidden {
				display: none;

				+ .filters__mobile-overlay {
					display: none;
				}
			}
		}

		&__mobile-close {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			padding: 29px $block-spacing * 4;
			font-size: 18px;
			color: $black;
			cursor: pointer;
		}

		&__toggle {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 55px;
			margin-bottom: 0;
			cursor: pointer;
			background-color: $white;
			border: 1px solid $border-color;
			font-size: 14px;
			color: $black;
			padding: 0 $block-spacing * 2;
			background-position: center right $block-spacing * 2;
			border-radius: 50px;
			box-shadow: 2px 2px $border-color;

			&--mobile {
				background-color: $brand-color;
				border: 1px solid $brand-color;
				color: $white;
				justify-content: center;
				margin-top: $block-spacing * 5;
			}
		}

		&__icon {
			font-size: 20px;
			color: $black;
		}

		&__title {
			font-size: 12px;
			text-transform: uppercase;
			color: $black;
			letter-spacing: 1px;
		}

		&__count {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white;
			height: 30px;
			width: 30px;
			border-radius: 30px;
			font-size: 12px;
			background-color: $highlight-color;
			font-weight: bold;
		}
	}
}
