.select {
	position: relative;
	background: url('#{$image-path}/icons/angle-down.svg') no-repeat center right 8px;
	background-size: 8px;
	background-color: $white;
	padding: $block-spacing * 2 $block-spacing * 5 $block-spacing * 2 $block-spacing * 2;
	background-position: center right $block-spacing * 2;
	border: 1px solid $border-color;
	border-radius: 27px;
	box-shadow: 2px 2px $border-color;
	cursor: pointer;
	z-index: 1;
	height: 54px;
	width: 100%;

	&__label {
		position: absolute;
		top: $block-spacing * 1.5;
		left: $block-spacing * 2;
		font-size: 9px;
		color: $gray;
		text-transform: uppercase;
		font-weight: 500;
	}

	&__selected {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.5em;
		color: $black;
		position: absolute;
		bottom: $block-spacing;
		left: $block-spacing * 2;
	}

	&__option {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.5em;
		color: $black;

		&--hidden {
			display: none;
		}
	}

	&__dropdown {
		display: none;
		position: absolute;
		top: 100%;
		left: -1px;
		right: -1px;
		padding: $block-spacing $block-spacing * 2 $block-spacing;
		background-color: $white;
		border: 1px solid $border-color;
		border-top: $block-spacing * 2;
		border-radius: 0 0 27px 27px;
		box-shadow: 2px 2px $border-color;
	}

	&__replacement {
		color: $black;
		padding: $block-spacing 0;
		cursor: pointer;
		display: block;

		&:hover {
			color: $brand-color;
		}
	}

	&__input {
		display: none;

		&:checked + .select__replacement {
			color: $brand-color;
			font-weight: bold;
		}
	}

	&--open {
		border-radius: 27px 27px 0 0;
		z-index: 2;

		&::after {
			content: ' ';
			position: absolute;
			bottom: -1px;
			left: -1px;
			right: -1px;
			display: block;
			height: 27px;
			border: 1px solid $border-color;
			border-top: 0;
			border-radius: 0 0 27px 27px;
		}

		.select__dropdown {
			display: block;
		}
	}

	&--small {
		width: calc(50% - #{$block-spacing});
	}

	&--number {
		width: 30%;
	}

	&--hidden {
		display: none;
	}

	&--sizecup {
		margin-right: $block-spacing * 2;
	}
}
