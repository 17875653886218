@include news-item {
	padding-bottom: 80px;

	&__image {
		border-radius: 50px;

		&::after {
			padding-bottom: 110%;
		}
	}

	&__label {
		background-color: $background-color-2;
		height: 64px;
		width: 64px;
		border-radius: 64px;
		position: absolute;
		top: $block-spacing * 4;
		left: $block-spacing * 4;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-family: $secondary-font-family;
		font-size: 18px;
		line-height: 18px;

		span {
			width: 100%;
		}
	}

	&__author {
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: $block-spacing * 3;
		left: 50%;
		transform: translateX(-50%);
		max-width: calc(100% - #{$block-spacing * 2});
		background-color: $white;
		border-radius: 54px;
		font-size: 16px;
		font-weight: 500;
		padding: $block-spacing / 2 $block-spacing * 2 $block-spacing / 2 $block-spacing / 2;

		strong {
			font-weight: bold;
		}

		img {
			width: 46px;
			height: 46px;
			max-width: 46px;
			max-height: 46px;
			border-radius: 46px;
			margin-right: $block-spacing * 2;
		}
	}

	&__author-image {
		width: 46px;
		height: 46px;
		border-radius: 46px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: $block-spacing;
	}

	&__name {
		font-size: 14px;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 1.4px;
		font-weight: 500;
		margin-top: $block-spacing;
		max-height: 24px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__title {
		text-align: center;
		font-size: 32px;
		line-height: 48px;
		font-family: $secondary-font-family;
		width: 100%;
		max-height: 96px;
		overflow: hidden;
	}

	&__text {
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		position: relative;
		width: 100%;
		max-height: 96px;
		overflow: hidden;
	}

	&__container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		align-items: center;
		justify-content: space-between;
		display: flex;
		padding-top: $block-spacing * 3;

		&::before {
			position: absolute;
			left: 0;
			top: 0;
			width: 50%;
			display: block;
			content: ' ';
			height: 4px;
			background-color: $background-color-2;
		}
	}

	.button {
		width: 50%;
	}

	&__date {
		font-size: 12px;
		color: $gray;
		width: 50%;
		text-align: center;
		margin: 0;
	}

	&--small {
		padding-bottom: $block-spacing * 2;

		.news-item__image {
			&::after {
				padding-bottom: 75%;
			}
		}

		.news-item__title {
			font-size: 24px;
			line-height: 1.5em;
			max-height: 36px;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.news-item__container {
			display: none;
		}

		.news-item__text {
			display: none;
		}
	}

	&--caption {
		background-color: $white;
		border-radius: 50px;
		width: 526px;
		max-width: 100%;
		padding: 0 $block-spacing * 2;
		margin-top: 83px;
		height: calc(100% - 249px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.news-item__content {
			width: 100%;
		}

		.news-item__author {
			border: 1px solid $border-color;
			position: unset;
			transform: none;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			margin: 0 $block-spacing;
		}

		.news-item__date {
			width: auto;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			padding: $block-spacing * 2 $block-spacing * 4 0;

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: ' ';
				display: block;
				background-color: $background-color-2;
				height: 4px;
				width: 100%;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--caption {
			position: absolute;
			margin: 0;
			bottom: 50px;
			width: 100%;
			height: auto;
			padding: $block-spacing * 4 $block-spacing * 2 $block-spacing * 2;
		}
	}

	@include responsive(('tablet', 'tablet-landscape', 'desktop', 'desktop-hd')) {
		&--big {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 64px;

			&::after {
				position: absolute;
				bottom: 24px;
				left: 0;
				display: block;
				content: ' ';
				width: 100%;
				height: 1px;
				background-color: $background-color-2;
			}

			.news-item__image {
				width: 50%;

				&::after {
					padding-bottom: 75%;
				}
			}

			.news-item__author {
				top: 37px;
				right: 32px;
				transform: none;
				bottom: auto;
				left: auto;
			}

			.news-item__content {
				width: 50%;
				padding: $block-spacing 8.33%;
			}

			.news-item__container {
				position: relative;
				bottom: auto;
				left: auto;
				right: auto;
			}
		}
	}

	@include responsive(('tablet')) {
		&--big {
			.news-item__content {
				padding: $block-spacing $block-spacing * 4;
			}
		}
	}
}
