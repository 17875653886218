@include sorting {
	justify-content: flex-end;
	width: calc(100% - #{$block-spacing * 2});
	margin: 0 $block-spacing;
	position: relative;
	margin-bottom: 48px;

	&__content {
		font-size: 16px;
		color: $black;
		padding: 0;
		position: absolute;
		top: 100%;
		left: 0;
	}

	&__select {
		background-color: $white;
		border: 1px solid $border-color;
		font-size: 14px;
		font-weight: 500;
		color: $black;
		padding: $block-spacing * 2 $block-spacing * 5 $block-spacing * 2 $block-spacing * 2;
		background-position: center right $block-spacing * 2;
		border-radius: 50px;
		box-shadow: 2px 2px $border-color;
	}

	&::before {
		position: absolute;
		top: 50%;
		left: 0;
		right: auto;
		display: block;
		content: ' ';
		height: 1px;
		width: 100%;
		background-color: $border-color;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		align-items: center;
		justify-content: center;
		margin-top: $block-spacing * 2;

		&__content {
			text-align: center;
			width: 100%;
			padding-top: $block-spacing;
		}
	}
}
