.tooltip {
	position: relative;
	display: inline;

	&__message {
		font-family: $primary-font-family;
		border-radius: 5px;
		background: $font-color;
		display: none;
		padding: 8px;
		color: $white;
		font-size: 12px;
		line-height: 24px;
		min-width: 185px;
		max-width: 100%;
		position: absolute;
		top: 100%;
		font-weight: bold;
		z-index: 2;
		text-transform: capitalize;

		&::after {
			position: absolute;
			content: ' ';
			bottom: 100%;
			right: 0;
			height: 5px;
			width: 100%;
			background: transparent;
		}

		a {
			text-decoration: none;
		}
	}

	&__icon {
		color: $font-color;
		font-size: 12px;
		text-decoration: none !important;

		&--center {
			vertical-align: text-top;
			line-height: 32px !important;
		}

		&:hover {
			color: $font-color;

			& + .tooltip__message {
				display: block;
			}
		}
	}

	&:hover {
		.tooltip__message {
			display: block;
		}
	}
}
