.calculator {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-wrap: wrap;

	&__image {
		width: 34%;
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		min-height: 560px;
		background-size: contain;
	}

	&__view {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: center;
	}

	&__steps-bottom {
		display: flex;
		width: 100%;
		overflow-x: auto;
		padding: $block-spacing 0;
		gap: $block-spacing;

		i {
			margin: 0 $block-spacing;
		}

		span {
			color: $black;
			font-size: 12px;
			font-weight: 500;
			cursor: pointer;

			& span {
				text-decoration: underline;
				color: $brand-color;
			}

			&:last-child {
				i {
					display: none;
				}
			}
		}

		.bottom__step {
			white-space: nowrap;

			&--hidden {
				display: none;
			}
		}
	}

	&__step {
		background-color: $background-color;
		padding: $vertical-spacing $horizontal-spacing;
		width: 66%;

		&--hidden {
			display: none;
		}

		.product {
			height: auto;

			&:first-child {
				width: 50%;
				margin: 0 25%;

				@include responsive(('mobile')) {
					width: 100%;
					margin: 0;
				}
			}

			&:not(:first-child) {
				width: 32%;

				@include responsive(('mobile')) {
					width: 100%;
				}
			}
		}

		.media__buttons {
			width: 100%;
			gap: 8px;

			span {
				width: 100%;
				text-align: center;
			}

			@include responsive(('mobile', 'mobile-landscape')) {
				.button {
					width: 100%;
				}
			}
		}
	}

	&__options {
		display: flex;
		flex-wrap: wrap;
		padding-top: $block-spacing * 3;

		.checkbox {
			margin-right: $block-spacing;
			margin-bottom: $block-spacing;
		}
	}

	&__steps-top {
		font-size: 16px;
		width: 100%;
		margin-top: $block-spacing * 2;
	}

	&__steps {
		width: 100%;
		margin: 0;
		position: relative;
		height: 54px;
		margin-top: $block-spacing * 2;

		&::before {
			position: absolute;
			top: 50%;
			left: 0;
			right: auto;
			display: block;
			content: ' ';
			height: 1px;
			width: 100%;
			background-color: $border-color;
		}
	}

	&__prev-step {
		position: absolute;
		top: 0;
		right: 86px;
		height: 54px;
		border-radius: 54px;
		background-color: $background-color;
		color: $black;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		padding: 0 $block-spacing * 2;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			background-color: rgba($background-color, 0.8);
		}

		&--hidden {
			display: none;
		}
	}

	&__next-step {
		position: absolute;
		top: 0;
		right: $block-spacing * 2;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $brand-color;
		color: $white;
		font-size: 16px;
		height: 54px;
		width: 54px;
		border-radius: 54px;
		cursor: pointer;

		&--hidden {
			display: none;
		}

		&:hover {
			background-color: rgba($brand-color, 0.8);
		}
	}

	&__button {
		&--hidden {
			display: none;
		}
	}

	&__loader {
		@extend %flex-column;
		@extend %paragraph;

		z-index: 1;
		display: none;
		align-items: center;
		justify-content: center;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba($black, 0.7);
		color: $white;
	}

	&--loading {
		.calculator__loader {
			display: flex;
		}
	}

	.size_calculator {
		.mdc-notched-outline * {
			background-color: $white;
		}
	}

	.product-results {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		gap: 8px;
		text-align: center;
		justify-content: center;

		.product__image-placeholder::after {
			padding-bottom: 120%;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__step {
			padding: $vertical-spacing-mobile $horizontal-spacing-mobile;
		}

		&__steps-top {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing * 3;
			text-align: center;
		}

		&__steps {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__prev-step {
			position: unset;
			top: auto;
			right: auto;
			z-index: 1;
			margin-right: $block-spacing / 2;
		}

		&__next-step {
			position: unset;
			top: auto;
			right: auto;
			z-index: 1;
			margin-left: $block-spacing / 2;
		}

		&__steps-bottom {
			white-space: nowrap;
			overflow-y: auto;
			margin-top: $block-spacing * 3;
			padding-bottom: $block-spacing;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__image {
			width: 100%;
			min-height: 315px;
			background-size: contain;
			margin-bottom: $block-spacing * 2;
		}

		&__step {
			width: 100%;
		}
	}

	@include responsive(('mobile')) {
		.checkbox {
			width: calc(50% - #{$block-spacing});
		}

		.checkbox__replacement--button {
			min-width: unset;
			width: 100%;
		}
	}
}
