@mixin carousel {
	@at-root .carousel {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		opacity: 0;
		transition: opacity 300ms ease-in-out;

		&--initialized {
			opacity: 1;
		}

		&__slides {
			height: 100%;
			font-size: 0;
		}

		&__slide {
			vertical-align: top;
			height: 100%;
			display: inline-block;
			white-space: normal;
			padding: $block-spacing;

			&--no-spacing {
				padding: 0;
			}
		}

		&__prev {
			@extend %position-left;

			left: $block-spacing;

			@include responsive('mobile') {
				left: 0;
			}
		}

		&__next {
			@extend %position-right;

			right: $block-spacing;

			@include responsive('mobile') {
				right: 0;
			}
		}

		&__next,
		&__prev {
			outline: none;
			cursor: pointer;
			height: 56px;
			width: 56px;
			color: $font-color;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $border-color;
			background-color: rgba($white, 0.5);
			transition: background-color 0.3s ease-in-out 0s;
			z-index: 8;

			&:hover {
				background-color: $white;
			}
		}

		&__progress {
			@extend %flex-row;

			position: absolute;
			justify-content: center;
			align-items: center;
			padding: $block-spacing;
		}

		&__bullet {
			height: 20px;
			width: 20px;
			border-radius: 20px;
			background-color: $background-color;
			margin: $block-spacing;
			cursor: pointer;

			&:hover {
				background-color: $hover-color;
			}

			&--active {
				background-color: $active-color;
			}
		}

		@content;
	}
}
