.category {
	display: block;

	&__image {
		display: block;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 100%;
		}
	}

	&__name {
		@extend %heading6;

		color: $black;
		font-family: $secondary-font-family;
		font-weight: bold;
		text-align: center;
		margin-top: $block-spacing * 2;
	}
}
