@include guide {
	font-size: 12px;
	color: $black;
	justify-content: space-between;
	padding: $block-spacing * 2 $horizontal-spacing;
	margin: 0 auto;
	max-width: $grid-width;

	&__container {
		@extend %flex-row;

		align-items: center;

		&:first-child {
			justify-content: flex-start;

			.guide__item {
				text-transform: uppercase;
			}
		}

		&:last-child {
			justify-content: flex-end;
		}
	}

	&__item {
		@extend %flex-row;

		align-items: center;
		padding: $block-spacing;

		&:hover {
			text-decoration: none;
			color: $brand-color;
		}

		&:only-child {
			color: inherit;
		}

		&:last-child {
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}

	&__icon {
		font-size: 30px;
		margin-left: 0;
		margin-right: $block-spacing;
	}

	&--light {
		color: $white;
	}

	&--dark {
		color: $black;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		padding: 0 $horizontal-spacing-mobile;
		overflow-x: auto;
		max-width: 100%;
		white-space: nowrap;
		flex-wrap: wrap;

		&__container {
			width: 30%;

			&:last-child {
				width: 70%;
			}
		}
	}

	@include responsive(('mobile')) {
		font-size: 10px;
	}
}
