.social {
	@extend %flex-row;

	align-items: center;
	justify-content: center;
	margin: $block-spacing * 3 0;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		color: $brand-color;
		margin: $block-spacing / 2;

		&:hover {
			color: $highlight-color;
		}
	}

	&--big {
		justify-content: flex-start;

		.social__item {
			font-size: 32px;
			margin-right: $block-spacing * 3;
		}
	}

	&--footer {
		margin-bottom: 0;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--big {
			justify-content: center;

			.social__item {
				margin: $block-spacing / 2 $block-spacing * 1.5;
			}
		}
	}
}
