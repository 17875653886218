@function column-width($column) {
	@return 100 / 12 * $column * 1%;
}

@mixin grid {
	@at-root .grid {
		@extend %flex-row;

		position: relative;
		width: $grid-width;
		max-width: 100%;
		margin: 0 auto;
		box-sizing: border-box;

		&__col {
			position: relative;
			padding: $grid-column-vertical-spacing $grid-column-horizontal-spacing;
			box-sizing: border-box;
			flex: 0 1 auto;

			@for $column from 1 to 13 {
				&--#{$column} {
					width: #{column-width($column)};
				}
				&--order-#{$column} {
					order: #{$column};
				}
			}

			@each $breakpoint in map-keys($grid-responsive-modes) {
				@for $column from 1 to 13 {
					&--#{$breakpoint}-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							width: #{column-width($column)};
						}
					}
					&--#{$breakpoint}-order-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							order: #{$column};
						}
					}
				}
			}

			@for $column from 1 to 12 {
				&--offset-#{$column} {
					margin-left: column-width($column);
				}
			}

			@each $breakpoint in map-keys($grid-responsive-modes) {
				@for $column from 1 to 12 {
					&--offset-#{$breakpoint}-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							margin-left: column-width($column);
						}
					}
				}
				&--no-offset-#{$breakpoint} {
					@media #{map-get($grid-responsive-modes, $breakpoint)} {
						margin-left: 0;
					}
				}
			}

			&--no-spacing {
				padding: 0;
			}
		}

		&--spacing {
			padding: 0 $grid-spacing;
		}

		&--full-width {
			width: 100%;
			max-width: calc(#{$grid-width * 2} - 2px);
		}

		&--justify-center {
			justify-content: center;
		}

		&--space-evenly {
			justify-content: space-evenly;
		}

		&--space-around {
			justify-content: space-around;
		}

		&--space-between {
			justify-content: space-between;
		}

		&--end {
			justify-content: flex-end;
		}

		&--flex-end {
			align-items: flex-end;
		}

		&--center {
			align-items: center;
		}

		&--stretch {
			align-items: stretch;
		}

		&--baseline {
			align-items: baseline;
		}

		@content;
	}
}
