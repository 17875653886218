@mixin language {
	@at-root .language {
		@extend %flex-row;

		align-items: center;
		padding: calc(#{$block-spacing} / 2);

		&__items {
			padding: 0 calc(#{$block-spacing} / 2);
		}

		&__item {
			padding: calc(#{$block-spacing} / 2);

			&--active,
			&:hover {
				background-color: $background-color;
			}
		}

		&__flag,
		img {
			display: block;
			width: 20px;
		}

		&--dropdown {
			position: relative;
			flex: none;
			cursor: pointer;
			z-index: 1;

			.language__items {
				display: none;
				position: absolute;
				top: 100%;
				left: 0;
				background: $white;

				.language__item {
					display: block;
					width: 100%;
				}
			}

			&:hover {
				.language__items {
					display: block;
				}
			}
		}

		@content;
	}
}
