@include carousel {
	&__next,
	&__prev {
		border-radius: 64px;
		height: 64px;
		width: 64px;
		background-color: $highlight-color-3;
		border: 0;
		font-size: 32px;
		color: $white;

		&:hover {
			background-color: rgba($highlight-color-3, 0.8);
			color: $white;
		}

		&--small {
			height: 49px;
			width: 49px;
			font-size: 20px;
		}
	}

	&__bullet {
		background-color: $white;

		&--active,
		&:hover {
			background-color: $brand-color;
		}
	}

	&__progress--hero-home {
		display: none;
		padding: 0;
		bottom: $block-spacing * 7 !important;

		.carousel__bullet {
			height: 5px;
			width: 5px;
			margin: $block-spacing / 4;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			display: flex;
		}
	}

	&--hidden {
		display: none;
	}
}

.custom-carousel {
	&__next,
	&__prev {
		outline: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.3s ease-in-out 0s;
		border-radius: 64px;
		height: 64px;
		width: 64px;
		background-color: $highlight-color-3;
		border: 0;
		font-size: 32px;
		color: $white;

		&:hover {
			background-color: rgba($highlight-color-3, 0.8);
			color: $white;
		}
	}

	&__progress {
		@extend %flex-row;

		position: absolute;
		justify-content: center;
		align-items: center;
		padding: $block-spacing;
	}

	&__bullet {
		height: 20px;
		width: 20px;
		border-radius: 20px;
		background-color: $background-color;
		margin: $block-spacing;
		cursor: pointer;

		&:hover {
			background-color: $hover-color;
		}

		&--active {
			background-color: $active-color;
		}
	}

	&__progress--hero-home {
		display: none;
		padding: 0;
		bottom: $block-spacing * 7 !important;

		.custom-carousel__bullet {
			height: 5px;
			width: 5px;
			margin: $block-spacing / 4;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			display: flex;
		}
	}
}
