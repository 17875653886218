.product-tags {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;

	&__title {
		font-size: 2.75rem;
		line-height: 1.5em;
		font-family: $secondary-font-family;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	&__tag {
		display: block;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 1.4px;
		line-height: 24px;
		color: $gray;
		position: relative;
		text-transform: uppercase;
		margin-right: 32px;
		cursor: pointer;

		&--active,
		&:hover {
			color: $black;

			&::after {
				content: ' ';
				display: block;
				background-color: $black;
				height: 2px;
				width: 100%;
				position: absolute;
				left: 0;
				bottom: -10px;
			}
		}

		&:last-child {
			color: $black;
			margin-left: 32px;
			margin-right: 0;

			&::before {
				content: ' ';
				display: block;
				background-color: $background-color;
				width: 1px;
				height: 33px;
				position: absolute;
				top: 50%;
				left: -32px;
				transform: translateY(-50%);
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__title {
			width: 100%;
			text-align: center;
			font-size: 24px;
			margin-bottom: $block-spacing * 3;
		}

		&__container {
			width: 100%;
			justify-content: flex-start;
			overflow-x: auto;
			overflow-y: hidden;
			padding-bottom: $block-spacing * 2;
		}

		&__tag {
			white-space: nowrap;
		}
	}
}
