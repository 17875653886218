@mixin tabs {
	@at-root .tabs {
		width: 100%;

		&__container {
			display: flex;
			flex-wrap: wrap;
		}

		&__tab {
			@extend %heading6;

			position: relative;
			padding: $block-spacing * 2;
			background: $background-color;
			cursor: pointer;
			border-left: 1px solid $border-color;
			border-top: 1px solid $border-color;

			&::after {
				content: ' ';
				position: absolute;
				top: 100%;
				left: 0;
				height: 1px;
				width: 100%;
				background: $border-color;
				transition: background 0.3s ease-in-out 0s;
			}

			&:last-child {
				border-right: 1px solid $border-color;
			}

			&--active,
			&:hover {
				background: $white;
			}

			&--active {
				&::after {
					background: $white;
				}
			}

			@include responsive(('mobile', 'mobile-landscape')) {
				width: 100%;
				border: 0;
			}
		}

		&__content {
			@extend %paragraph;

			border: 1px solid $border-color;
			background: $white;
			padding: $block-spacing * 4;
			display: none;

			@include responsive(('mobile', 'mobile-landscape')) {
				padding: $block-spacing * 2;
			}

			&--active {
				display: block;
			}
		}
		@content;
	}
}
