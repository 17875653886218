.mobile-filters {
	display: none;

	&__toggle {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		background-color: $white;
		height: 50px;
		border: 1px solid $border-color;
		padding: 0 $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
		cursor: pointer;
	}

	&__icon {
		font-size: 20px;
		color: $black;
	}

	&__title {
		font-size: 12px;
		text-transform: uppercase;
		color: $black;
		font-weight: bold;
		letter-spacing: 1px;
	}

	&__count {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		color: $white;
		height: 30px;
		width: 30px;
		font-size: 12px;
		background-color: $brand-color;
		font-weight: bold;
	}

	&__overlay {
		background-color: rgba($black, 0.4);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9;

		&--hidden {
			display: none;
		}
	}

	&__dropdown {
		background-color: $white;
		padding: 0 $block-spacing * 4;
		position: fixed;
		bottom: 0;
		right: 0;
		left: $block-spacing * 2;
		top: 95px;
		z-index: 9;
		color: $black;
		display: flex;
		flex-direction: column;

		&--hidden {
			display: none;
		}
	}

	&__top {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		padding: $block-spacing * 4 0;
	}

	&__heading {
		font-size: 18px;
		font-weight: bold;
		font-family: $secondary-font-family;
	}

	&__close {
		font-size: 16px;
		font-weight: bold;
		cursor: pointer;

		i {
			margin-left: $block-spacing / 2;
		}
	}

	&__back {
		@extend %flex-row;

		align-items: center;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;

		i {
			font-size: 30px;
			margin-right: $block-spacing;
		}
	}

	&__mid {
		flex: 1;
		overflow-y: auto;

		&--hidden {
			display: none;
		}
	}

	&__sorting {
		@extend %flex-row;

		align-items: center;
		justify-content: flex-start;
		flex-wrap: nowrap;
		overflow-x: auto;
		margin-bottom: $block-spacing * 3;
	}

	&__sorting-title {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: $block-spacing * 2;
		letter-spacing: 1px;
	}

	&__container {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		padding: $block-spacing 0;

		&--flex-start {
			justify-content: flex-start;
		}
	}

	&__item {
		@extend %flex-row;

		min-height: 86px;
		cursor: pointer;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		padding: $block-spacing * 3 0;
		border-bottom: 1px solid $border-color;

		i {
			font-size: 18px;
		}
	}

	&__sub-item {
		font-size: 18px;
		font-family: $secondary-font-family;
		border-bottom: 1px solid $border-color;
		padding: $block-spacing * 4 0;
	}

	&__name {
		font-family: $secondary-font-family;
		font-weight: bold;
		font-size: 18px;
	}

	&__selected {
		color: $font-color;
		font-size: 14px;
		margin-top: $block-spacing / 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		display: block;
	}
}
