@mixin news-item() {
	@at-root .news-item {
		@extend %paragraph;

		position: relative;
		display: block;
		height: 100%;

		&__image {
			position: relative;
			width: 100%;
			height: auto;
			background-size: cover;
			background-position: center;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}

		&__content {
			padding: $block-spacing;
		}

		&__date {
			margin-bottom: $block-spacing;
		}

		&__title {
			@extend %heading6;

			margin-bottom: $block-spacing;
		}

		&__text {
			margin-bottom: $block-spacing;
		}

		&__link {
			color: $highlight-color;
		}

		@content;
	}
}
