.sitemap {
	color: $font-color;
	margin-top: $block-spacing * 2;
	margin-bottom: $block-spacing * 2;
	width: 20%;
	padding: $block-spacing;
	text-align: center;

	&__title {
		text-transform: uppercase;
		font-size: 14px;
		display: block;
		font-weight: 500;
		letter-spacing: 1.4px;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
		position: relative;

		&::after {
			display: block;
			height: 3px;
			width: 100px;
			content: ' ';
			background-color: $background-color;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__item {
		display: block;
		font-size: 12px;
		line-height: 1.5em;
	}

	a.sitemap__item {
		&:hover {
			color: $brand-color;
		}
	}

	&--no-mb {
		margin-bottom: 0;
	}

	img {
		max-width: 80%;
	}

	&--mobile {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		margin: 0;

		&__title {
			margin-bottom: 0;

			&::after {
				width: 318px;
				max-width: 100%;
				height: 1px;
			}
		}

		&__item {
			&:first-child {
				margin-top: $block-spacing * 2;
			}
		}

		&--desktop {
			display: none;
		}

		&--mobile {
			display: block;
		}
	}

	@include responsive(('mobile')) {
		img {
			max-width: 100%;
		}
	}
}
