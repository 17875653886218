@mixin field {
	display: flex;
	width: 100%;

	.mdc-floating-label {
		font-family: $primary-font-family;
	}

	&--focused#{&}--outlined:not(&--disabled) .mdc-notched-outline {
		&__leading,
		&__notch,
		&__trailing {
			border-width: 2px;
		}
	}
}

@mixin mdc-field($color: $font-color, $hover-color: $hover-color, $focused-color: $active-color, $border-radius: 0) {
	@at-root .mdc-select {
		@include field;

		@include mdc-select-shape-radius($border-radius);

		@include mdc-select-label-color($color);
		@include mdc-select-focused-label-color($focused-color);

		@include mdc-select-outline-color($color);
		@include mdc-select-hover-outline-color($hover-color);
		@include mdc-select-focused-outline-color($focused-color);

		@include mdc-select-focused-bottom-line-color($focused-color);

		.mdc-notched-outline {
			z-index: 1;
		}

		.mdc-line-ripple--active {
			transform: scaleX(0);
		}

		&__dropdown-icon {
			@include mdc-select-dd-arrow-svg-bg_($color, 1);
		}

		&--focused &__dropdown-icon {
			@include mdc-select-dd-arrow-svg-bg_($focused-color, 1);
		}
	}

	@at-root .mdc-text-field {
		@include field;

		@include mdc-text-field-shape-radius($border-radius);

		@include mdc-text-field-label-color($color);

		&--focused {
			.mdc-floating-label {
				color: $focused-color !important;
			}
		}

		@include mdc-text-field-outline-color($color);
		@include mdc-text-field-hover-outline-color($hover-color);
		@include mdc-text-field-focused-outline-color($focused-color);

		textarea {
			resize: vertical;
			height: 188px !important;
		}
	}
}

.mdc-notched-outline__leading {
	width: 20px;
	border-radius: 20px 0 0 20px !important;
	border-width: 2px;
	border-color: $brand-color !important;
}

.mdc-notched-outline__notch {
	border-width: 2px;
	border-color: $brand-color !important;
	border-top: 2px solid $brand-color !important;
}

.mdc-floating-label {
	font-size: 1rem !important;
	transform: translateY(-130%) scale(0.75) !important;
	color: $brand-color !important;
	max-width: calc(100% / 0.75);
	text-overflow: clip;
	background-color: $white;
	padding: 0 8px;
}

.mdc-notched-outline__trailing {
	width: 20px;
	border-radius: 0 20px 20px 0 !important;
	border-width: 2px;
	border-color: $brand-color !important;
}

.mdc-select--focused,
.mdc-text-field--focused {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: $highlight-color !important;
	}

	.mdc-floating-label {
		color: $highlight-color !important;
	}
}

.mdc-select--invalid,
.mdc-text-field--invalid {
	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-color: $error-color !important;
	}

	.mdc-floating-label {
		color: $error-color !important;
	}
}

@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
	.mdc-text-field--outlined .mdc-floating-label,
	.mdc-text-field--textarea .mdc-floating-label,
	.mdc-select.mdc-select--outlined .mdc-floating-label {
		top: 12px;
	}
}
