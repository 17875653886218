@include language {
	color: $black;
	padding: 0;

	&__item {
		@extend %small;

		padding: 0 $block-spacing / 2;
		font-weight: bold;

		&:hover {
			background-color: transparent;
		}
	}

	&__items {
		padding: $block-spacing 0 0 0;
		width: 100%;

		.language__item {
			&:hover {
				color: $hover-color;
			}
		}
	}

	&__icon-left {
		font-size: 18px;
		margin: 0 $block-spacing 0 0;
	}

	&__icon-right {
		margin: 0 0 0 $block-spacing;
	}
}
