@include checkbox {
	@extend %flex-row;

	font-size: 14px;
	color: $black;
	align-items: flex-start;
	font-family: $primary-font-family;

	&__replacement {
		border-color: $gray;
		border-radius: 0;
		margin: 0 $block-spacing 0 0;

		&--button {
			min-width: 100px;
			text-align: center;
			width: auto;
			height: auto;
			padding: $block-spacing * 2;
			font-size: 14px;
			color: $black;
			background-color: $white;
			border: 1px solid $background-color;
			font-weight: 500;
			white-space: nowrap;
			border-radius: 27px;
			margin: 0;
		}
	}

	&__input:checked ~ .checkbox__content {
		color: $brand-color;
		font-weight: bold;
	}

	&__input:checked + .checkbox__replacement {
		border-color: $brand-color;

		&--button {
			color: $black;
			background: $highlight-color-3;
			border-color: $highlight-color-3;
		}
	}

	&__label {
		flex: 1;
	}

	a {
		text-decoration: underline;
	}

	&--filters {
		width: 100%;
		padding: $block-spacing 0;
	}

	&--shoppingcart {
		padding: $block-spacing * 2;
	}

	&:hover {
		text-decoration: underline;
	}

	&--hidden {
		display: none;
	}
}

.shoppingcart-total + .checkbox {
	justify-content: flex-end;
	margin-bottom: $block-spacing * 2;

	&:hover {
		text-decoration: none;
	}
}
