@include active-filters {
	background-color: $white;
	padding: 0 $block-spacing;
	align-items: center;
	margin: 0;

	&__count {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		color: $white;
		height: 30px;
		width: 30px;
		font-size: 12px;
		background-color: $brand-color;
		font-weight: bold;
		margin-right: $block-spacing;
	}

	&__content {
		font-size: 16px;
		color: $black;
		margin-right: $block-spacing * 2;
	}

	&__label {
		margin: 0;
		width: auto;
	}

	&__replacement {
		border: 0;
		color: $black;
		font-weight: bold;
		font-size: 16px;
		padding-left: 0;
		padding-right: 32px;

		i {
			right: 16px;
		}

		&:hover {
			background-color: transparent;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		display: none;

		&__content {
			margin-right: 0;

			span {
				display: block;
				padding: 2px 0;
			}
		}

		&--news {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
}

.form.active-filters {
	margin-top: $block-spacing * 2;
}
