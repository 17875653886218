@include background {
	padding: 0;
	margin: 0;

	&--home,
	&--article {
		border-radius: 50px 50px 0 0;
		margin-top: -83px;
	}

	&--home {
		border-radius: 50px 50px 0 0;
	}

	&--article {
		border-radius: 50px;
	}

	&--shop {
		border-radius: 50px;
	}

	&--product {
		border-radius: 50px 50px 0 0;
	}

	&--white {
		background-color: $white;
	}

	&--border-radius {
		border-radius: 50px;
	}

	&--shadow {
		box-shadow: 0 0 25px $black;
	}

	&--horizontal-spacing {
		padding-left: $horizontal-spacing;
		padding-right: $horizontal-spacing;
	}

	&--vertical-spacing {
		margin-top: $vertical-spacing;
		margin-bottom: $vertical-spacing;
	}

	&--vertical-spacing-top {
		margin-top: $vertical-spacing;
	}

	&--vertical-spacing-bottom {
		margin-bottom: $vertical-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--home,
		&--article {
			margin-top: -32px;
			border-radius: 50px;
		}

		&--vertical-spacing {
			margin-top: $vertical-spacing-mobile;
			margin-bottom: $vertical-spacing-mobile;
		}

		&--vertical-spacing-top {
			margin-top: $vertical-spacing-mobile;
		}

		&--vertical-spacing-bottom {
			margin-bottom: $vertical-spacing-mobile;
		}

		&--horizontal-spacing {
			padding-left: $horizontal-spacing-mobile;
			padding-right: $horizontal-spacing-mobile;
		}
	}
}
