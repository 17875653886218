// path
$font-path: '#{$root}/fonts';
$image-path: '#{$root}/img';

// fonts
$primary-font-family: 'Montserrat', sans-serif;
$secondary-font-family: 'Abril Fatface', cursive;

// colors
$black: #000;
$white: #fff;
$gray: #8b8b8b;

// brand color
$brand-color: #db007d;

// font colors
$font-color: $black;

// background colors
$background-color: #efe8e8;
$background-color-2: #f8dabb;
$background-color-3: #d19582;
$body-background-color: $background-color;

// border colors
$border-color: $background-color;

// placeholder colors
$placeholder-color: rgba($black, 0.6);

// overlay colors
$overlay-color: $black;

// highlight colors
$highlight-color: #ff8c13;
$highlight-color-2: #109b04;
$highlight-color-3: #f2a476;

// error and messages colors
$warning-color: #f5a623;
$error-color: #d01d1b;
$success-color: #68b21e;

// hover colors
$hover-color: $brand-color;

// active colors
$active-color: $brand-color;

$whatsapp-color: #7ba889;

// grid
$grid-width: 1640px;
$block-spacing: 8px;
$grid-spacing: $block-spacing;
$grid-column-horizontal-spacing: $block-spacing;
$grid-column-vertical-spacing: $block-spacing;

$vertical-spacing: $block-spacing * 6;
$vertical-spacing-mobile: $block-spacing * 3;

$horizontal-spacing: 4%;
$horizontal-spacing-mobile: $block-spacing * 3;

$responsive-max-resolutions: (
	desktop-hd: 1540,
	desktop: 1440,
	tablet-landscape: 1140,
	tablet: 960,
	mobile-landscape: 768,
	mobile: 568,
);

$grid-responsive-modes: (
	desktop: '(max-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	tablet-landscape: '(max-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px)',
	tablet: '(max-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px)',
	mobile-landscape: '(max-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px)',
	mobile: '(max-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px)',
);

$responsive-modes: (
	desktop-hd: '(min-width: #{(map-get($responsive-max-resolutions, desktop) - 1)}px)',
	desktop: '(min-width: #{(map-get($responsive-max-resolutions, tablet-landscape) - 1)}px) and #{map-get($grid-responsive-modes, desktop)}',
	tablet-landscape: '(min-width: #{(map-get($responsive-max-resolutions, tablet) - 1)}px) and #{map-get($grid-responsive-modes, tablet-landscape)}',
	tablet: '(min-width: #{(map-get($responsive-max-resolutions, mobile-landscape) - 1)}px) and #{map-get($grid-responsive-modes, tablet)}',
	mobile-landscape: '(min-width: #{(map-get($responsive-max-resolutions, mobile) - 1)}px) and #{map-get($grid-responsive-modes, mobile-landscape)}',
	mobile: '(min-width: 0px) and #{map-get($grid-responsive-modes, mobile)}',
);
