@include shoppingcart-popup {
	color: $black;

	&__total {
		font-size: 16px;
	}

	&__price {
		display: flex;
		flex-flow: column wrap;
		align-items: flex-end;
		color: $brand-color;

		s {
			color: $black;
		}
	}

	&__bottom {
		.button {
			&:last-child {
				margin-top: $block-spacing;
			}
		}
	}
}
