@include search {
	flex-grow: unset;
	padding: $block-spacing;
	z-index: 9;

	&__icon {
		font-size: 22px;
		position: absolute;
		right: 24px;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;
	}

	&__close {
		display: none;
	}

	&__input {
		font-size: 12px;
		color: $font-color;
		width: 288px;
		background-color: $white;
		border-radius: 50px;
		padding: $block-spacing * 2;
		padding-right: 46px;
		border: 1px solid $background-color-2;
		z-index: 10;

		&:focus,
		&:hover {
			border-color: $highlight-color;
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba($black, 0.2);
		z-index: -1;
		display: none;

		&--active {
			display: block;
		}
	}

	&__dropdown {
		border-radius: 50px;
		padding: $block-spacing * 4;
		width: 500px;
		left: $block-spacing;
		right: auto;
		max-height: calc(100vh - 130px);
		flex-direction: column;

		&--active {
			display: flex;
		}
	}

	&__scroll {
		height: 100%;
		overflow-y: auto;
		border-bottom: 1px solid $background-color;
	}

	&__heading {
		font-family: $secondary-font-family;
		color: $black;
		font-size: 18px;
		padding-bottom: $block-spacing * 4;
		border-bottom: 1px solid $background-color;
	}

	&__item {
		font-family: $primary-font-family;
		color: $black;
		font-size: 16px;
		display: block;
		padding: $block-spacing 0;

		strong {
			font-weight: bold;
		}

		span {
			color: $brand-color;
			font-weight: bold;
			text-decoration: underline;
		}

		&:hover {
			color: $brand-color;

			strong {
				color: $black;
			}
		}

		&--title {
			font-family: $secondary-font-family;
			font-size: 18px;
		}
	}

	&__container {
		border-bottom: 1px solid $background-color;
		padding-bottom: $block-spacing * 3;
		padding-top: $block-spacing * 3;

		&:last-child {
			border: 0;
		}
	}

	.button {
		margin-top: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $white;
		padding: 0 $block-spacing * 2 0 $block-spacing * 4;
		display: none;
		height: 78px;

		&__input {
			width: 100%;
		}

		&__icon {
			display: none;
		}

		&__close {
			display: block;
			cursor: pointer;
			font-size: 24px;
			padding: $block-spacing * 2;
		}

		&__overlay {
			height: calc(100vh - 78px);
			top: auto;
			bottom: 0;
		}

		&__dropdown {
			width: 100%;
			border-radius: 0;
			position: fixed;
			top: 78px;
			right: 0;
			bottom: 0;
			left: 0;
			max-height: unset;
			height: calc(100vh - 78px);
		}

		&--active {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
