@include size {
	margin: 0 $block-spacing $block-spacing 0;

	&__replacement {
		min-width: 50px;
		color: $black;
		font-size: 14px;
		padding: $block-spacing * 1.5 $block-spacing / 2;
		border: 1px solid $border-color;
		border-radius: 50px;
		background-color: $white;
		font-family: $primary-font-family;
		margin: 0;

		&--checked {
			background-color: $brand-color;
			color: $white;
			border-color: $brand-color;
			font-weight: bold;
		}

		&:hover {
			border-color: $brand-color;
		}
	}

	&--filters {
		flex: 1;
		display: flex;
		margin: $block-spacing / 2 $block-spacing $block-spacing / 2 0;

		.size__replacement {
			flex: 1;
			white-space: nowrap;
		}
	}

	&__input:checked + .size__replacement {
		background-color: $brand-color;
		color: $white;
		border-color: $brand-color;
		font-weight: bold;
	}
}
