.usp-banner {
	font-family: $primary-font-family;
	font-size: 10px;
	line-height: 22px;
	width: 100%;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	color: $font-color;
	background-color: $background-color-2;
	padding: $block-spacing $block-spacing * 2;
}
