@mixin background() {
	@at-root .background {
		background-color: $background-color;
		padding: $block-spacing;
		width: 100%;
		margin: $block-spacing 0;

		@content;
	}
}
