.sticky-bar {
	position: fixed;
	bottom: 30px;
	right: 30px;

	&__container {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	&__item {
		background-color: $black;
		width: 40px;
		height: 40px;
		margin-bottom: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $white;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}

		&--whatsapp {
			background-color: $whatsapp-color;
		}

		&--flipped {
			transform: rotate(180deg);
		}
	}

	&__icon {
		font-size: 18px;

		&--arrow {
			font-size: 16px;
		}
	}

	&__bubble {
		position: absolute;
		right: 0;
		top: -50px;
		width: 200px;
		background-color: $white;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border-radius: 50px;
		z-index: 2;
		animation-duration: 1s;
		animation-name: fade-out;
		animation-delay: 5s;
		animation-fill-mode: forwards;

		i {
			margin-right: 10px;
		}

		p {
			font-weight: bold;
		}

		&::before {
			content: '';
			width: 20px;
			height: 20px;
			position: absolute;
			z-index: 1;
			background-color: $white;
			bottom: -8px;
			right: 13px;
			transform: rotate(45deg);
		}

		@keyframes fade-out {
			from {
				opacity: 1;
			}

			to {
				opacity: 0;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		display: none;
	}
}
