@include product {
	border: 0;
	display: flex;
	flex-flow: column wrap;

	&__labels {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		display: flex;
		align-self: center;
		justify-content: space-between;
		gap: 5px;
	}

	&__label {
		font-size: 10px;
		line-height: 22px;
		border-radius: 50px;
		font-weight: bold;
		text-transform: uppercase;
		color: $white;
		background-color: $brand-color;
		padding: 0 $block-spacing * 2;
		display: inline-block;
		word-break: break-word;
	}

	&__name {
		color: $black;
		display: block;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: $block-spacing * 2;
		min-height: 42px;
	}

	&__colors {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		padding: $block-spacing;
		width: 100%;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			justify-content: flex-start;
			padding: $block-spacing 0;
		}
	}

	&__color {
		height: 24px;
		width: 24px;
		border-radius: 24px;
		border: 2px solid $white;
		box-shadow: 0 0 1px 1px black;
	}

	&__info {
		text-align: center;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		flex: 1 0 auto;
	}

	&__price {
		font-weight: bold;
		justify-content: center;

		&__advice {
			font-size: 12px;
			color: $black;
			padding: 0 2px;
		}

		&__highlight {
			color: $brand-color;
			font-size: 16px;
			padding: 0 2px;
		}
	}

	&__tags {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		min-height: 30px;
		gap: $block-spacing;
		padding-bottom: $block-spacing;
	}

	&__tag {
		position: unset;
		top: auto;
		left: auto;
		font-size: 10px;
		line-height: 22px;
		border-radius: 50px;
		font-weight: bold;
		text-transform: uppercase;
		color: $white;
		background-color: $background-color-3;
		padding: 0 $block-spacing * 2;
	}

	&__image-placeholder {
		margin-bottom: $block-spacing;
		background-color: $white;

		&::after {
			padding-bottom: 75%;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&--carousel {
			height: initial;
		}

		&--shop {
			display: flex;
			justify-content: center;
			flex-flow: row wrap;

			.product__image-placeholder {
				width: 50%;
				margin: 0;
			}

			.product__name {
				margin-bottom: $block-spacing / 2;
			}

			.product__tags {
				justify-content: flex-start;
			}

			.product__tag {
				margin-left: 0;
			}

			.product__info {
				width: 50%;
				text-align: left;
				justify-content: center;
			}

			.product__price {
				justify-content: flex-start;
			}
		}
	}
}
