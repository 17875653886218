body,
html {
	font-family: $primary-font-family;
	color: $font-color;
	font-size: 16px;

	@include responsive(('tablet')) {
		font-size: 14px;
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		font-size: 12px;
	}
}

html {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: $primary-font-family;
}

a {
	text-decoration: none;
	color: inherit;
}

strong {
	font-weight: bold;
}

%paragraph {
	font-size: 1rem;
	line-height: 1.5em;
}

%small {
	font-size: 0.875rem;
	line-height: 1.5em;
}

%heading1 {
	font-size: 3rem;
	line-height: 1.5em;
}

%heading2 {
	font-size: 2.75rem;
	line-height: 1.5em;
}

%heading3 {
	font-size: 2.5rem;
	line-height: 1.5em;
}

%heading4 {
	font-size: 2rem;
	line-height: 1.5em;
}

%heading5 {
	font-size: 1.5rem;
	line-height: 1.5em;
}

%heading6 {
	font-size: 1.125rem;
	line-height: 1.5em;
}

%ordered-list {
	list-style: decimal inside;
}

%unordered-list {
	list-style: disc inside;
}
