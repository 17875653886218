.category-buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	.button {
		width: calc(33.3333% - #{$block-spacing * 2});
		margin: $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		.button {
			width: calc(50% - #{$block-spacing * 2});
		}
	}
}
