.payment-icons {
	@extend %flex-row;

	width: 100%;
	align-items: center;
	justify-content: flex-start;
	padding: $block-spacing * 3 $block-spacing 0;

	&__icon {
		max-height: 40px;
		max-width: 80px;
		margin-right: $block-spacing * 3;

		&:first-child {
			margin-left: 0;
		}
	}

	@include responsive(('desktop')) {
		&__icon {
			max-height: 30px;
			margin-right: $block-spacing * 2;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		justify-content: center;

		&__icon {
			margin: $block-spacing * 2;
			max-height: 20px;
		}
	}
}
