@include pagination {
	background-color: $white;
	position: relative;
	width: 100%;
	padding: 0;
	min-height: 54px;

	&__pages {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__page {
		z-index: 1;
		height: 54px;
		width: 54px;
		border-radius: 54px;
		background-color: $background-color;
		color: $black;
		font-size: 14px;
		cursor: pointer;

		&:hover,
		&--active {
			color: $white;
			background-color: $brand-color;
		}
	}

	&__left {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__right {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__next {
		z-index: 1;
		background-color: $white;
		border: 1px solid $border-color;
		font-size: 14px;
		font-weight: 500;
		color: $black;
		padding: 0 $block-spacing * 2;
		height: 54px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		box-shadow: 2px 2px $border-color;
		cursor: pointer;
		text-align: center;

		&:hover {
			color: $white;
			background-color: $brand-color;
		}
	}

	&__back {
		z-index: 1;
		background-color: $white;
		border: 1px solid $border-color;
		height: 54px;
		width: 54px;
		color: $black;
		border-radius: 54px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: $block-spacing;
		font-size: 16px;
		box-shadow: 2px 2px $border-color;
		cursor: pointer;

		&:hover {
			color: $white;
			background-color: $brand-color;
		}
	}

	&__prev {
		z-index: 1;
		background-color: $background-color;
		height: 54px;
		width: 54px;
		color: $black;
		border-radius: 54px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: $block-spacing;
		font-size: 16px;
		cursor: pointer;

		&:hover {
			color: $white;
			background-color: $brand-color;
		}
	}

	&__content {
		z-index: 1;
		font-size: 16px;
	}

	&::before {
		display: block;
		height: 1px;
		width: 100%;
		position: absolute;
		background-color: $border-color;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		content: ' ';
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding: $block-spacing * 5 0;

		&__left {
			right: 0;
			align-items: center;
		}

		&__right {
			position: unset;
			top: auto;
			right: auto;
			bottom: auto;
		}

		&__pages {
			display: none;
		}
	}
}
