$root: '/_naron' !default;
@charset 'utf-8';
@import '~reset-css/sass/reset';
@import '~@material/textfield/mdc-text-field';
@import '~@material/select/mdc-select';
@import '~magnific-popup/src/css/main';
@import '~magnific-popup/dist/magnific-popup.css';
@import '~swiper/css/bundle';
@import '~vanilla-cookieconsent/dist/cookieconsent.css';
@import '~aos/dist/aos.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

@import 'variables';

@import 'mixins';
@import 'placeholders';

@import 'misc';
@import 'typography';
@import '../common.blocks/index';
@import '../custom.blocks/index';

@include account-nav {
	color: $black;
}
@include account-pagination;
@include account-popup;
@include account-register;
@include account-table {
	color: $black;
}
@include address;
@include form;
@include list {
	color: $black;
}
@include mdc-field;
@include message;
@include overlay {
	&--background,
	&--main {
		background-color: rgba($overlay-color, 0.37);
	}
}
@include shoppingcart-giftcard;
@include shoppingcart-total {
	color: $black;
}
@include tabs;
@include storelocator;

#s-bl .title {
	font-size: 16px;
}
