@include button {
	font-size: 14px;
	padding: $block-spacing * 2;
	border-radius: 54px;
	border: 1px solid transparent;
	font-weight: 500;
	font-family: $primary-font-family;

	&--1 {
		background-color: $brand-color;
		border: 1px solid $brand-color;
		color: $white;

		&:hover {
			background-color: rgba($brand-color, 0.8);
		}
	}

	&--2 {
		background-color: transparent;
		border: 1px solid $black;
		color: $black;

		&:hover {
			text-decoration: underline;
			background-color: transparent;
		}
	}

	&--3 {
		background-color: $background-color-2;
		color: $black;

		&:hover {
			background-color: rgba($background-color-2, 0.8);
		}
	}

	&--4 {
		background-color: transparent;
		border: 1px solid $brand-color;
		color: $brand-color;

		&:hover {
			text-decoration: underline;
			background-color: transparent;
		}
	}

	&--small-radius {
		border-radius: 20px;
	}

	&--filters {
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		font-size: 12px;
		background-color: transparent;
		margin-top: $block-spacing * 2;

		&:hover {
			background-color: transparent;
			color: $brand-color;
		}
	}

	&--shadow {
		box-shadow: 4px 4px $background-color;
	}

	&--add-to-cart {
		background-color: $brand-color;
		color: $white;
		text-align: center;
		width: calc(70% - #{$block-spacing * 2});
		margin-left: $block-spacing * 2;
		box-shadow: 2px 2px $border-color;

		&:hover {
			background-color: rgba($brand-color, 0.8);
		}
	}

	&--position-right {
		right: $block-spacing;
	}

	&--spacing {
		margin-top: $block-spacing * 4;
		margin-bottom: $block-spacing * 4;
	}

	&--spacing-top {
		margin-top: $block-spacing * 4;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing * 4;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--full-width-mobile {
			width: 100%;
		}

		&--position-mobile {
			position: unset;
			right: auto;
			top: auto;
			bottom: auto;
			left: auto;
			transform: none;
			margin-left: calc(8.3333% + #{$block-spacing});
			margin-right: calc(8.3333% + #{$block-spacing});
			margin-top: $block-spacing * 3;
			width: calc(100% - #{$block-spacing * 2});
		}

		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}
	}
}
