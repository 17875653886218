@mixin logo {
	@at-root .logo {
		position: relative;
		display: block;
		height: 50px;
		width: 100px;
		margin: $block-spacing;

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;
		}

		@content;
	}
}
