@include sidebar {
	background-color: $white;
	padding: $block-spacing * 4;

	&__heading {
		font-size: 18px;
		color: $black;
		font-family: $secondary-font-family;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 4;
		margin-bottom: $block-spacing * 2;
	}

	&__item {
		font-size: 16px;
		color: $black;
		font-family: $secondary-font-family;
		padding: $block-spacing * 2 0;

		&:last-child {
			padding-bottom: 0;
		}
	}
}
