@mixin shoppingcart-popup {
	@at-root .shoppingcart-popup {
		@extend %position-top-right;

		position: fixed;
		display: flex;
		flex-direction: column;
		width: 500px;
		max-width: 100%;
		height: 100%;
		z-index: 20;
		background: $white;
		border-left: 1px solid $background-color;
		transform: translateX(0);
		transition: transform 200ms ease-in-out;

		&__close {
			@extend %position-right;

			cursor: pointer;
			right: $block-spacing * 2;
			font-size: 14px;
			color: $font-color;
		}

		&__top {
			padding: $block-spacing * 2;
			color: $font-color;
			font-family: $primary-font-family;
			font-size: 14px;
			font-weight: bold;
			line-height: 21px;
			width: 100%;
			border-bottom: 1px solid $background-color;
			z-index: 1;
			background: $white;
			position: relative;
		}

		&__content {
			height: 100%;
			padding: $block-spacing * 2;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			flex: 1;
		}

		&__bottom {
			background: $white;
			width: 100%;
			padding: $block-spacing;
			border-top: 1px solid $background-color;
		}

		&__price-table {
			width: 100%;
			color: $font-color;
			font-family: $primary-font-family;
			font-size: 12px;
			line-height: 18px;
			text-transform: uppercase;
			margin-bottom: $block-spacing;

			td {
				&:last-child {
					text-align: right;
				}
			}

			strong {
				font-weight: bold;
			}
		}

		&__loader {
			@extend %flex-column;
			@extend %paragraph;

			z-index: 1;
			display: none;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: rgba($black, 0.7);
			color: $white;

			i {
				font-size: 24px;
			}
		}

		&--active {
			display: block;
		}

		&--hidden {
			transform: translateX(100%);
		}

		&--loading {
			.shoppingcart-popup__loader {
				display: flex;
			}
		}

		@content;
	}
}
