.product-images {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-right: 1px solid $border-color;
	min-height: 100%;
	margin-top: -$block-spacing;
	padding-bottom: $block-spacing * 4;
	padding-top: $block-spacing * 4;

	&--hidden {
		display: none;
	}

	&__image {
		display: block;
		width: 100%;

		&--thumb {
			border-radius: 27px;
		}
	}

	&__swiper {
		&--hidden {
			display: none;
		}

		&--invisible {
			visibility: hidden;
		}
	}

	&__hide-first {
		display: none;
		position: absolute;
		height: 0;
		width: 0;
	}

	&__container {
		position: relative;

		&--left {
			width: 50%;
			padding: $block-spacing;
			padding-left: $block-spacing * 4;
		}

		&--right {
			width: 50%;
			padding-right: $block-spacing * 4;

			.carousel__prev,
			.carousel__next {
				display: none;
			}
		}

		&--thumbs {
			width: 100%;

			.carousel__next {
				right: 32px;
			}

			.carousel__prev {
				left: 32px;
			}

			.swiper {
				padding: 0 100px;
				position: relative;

				&::before {
					height: 100%;
					width: 100px;
					background-color: $white;
					position: absolute;
					left: 0;
					top: 0;
					content: ' ';
					z-index: 9;
				}

				&::after {
					height: 100%;
					width: 100px;
					background-color: $white;
					position: absolute;
					right: 0;
					top: 0;
					content: ' ';
					z-index: 9;
				}
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__container {
			&--left {
				display: none;
			}

			&--right {
				width: 100%;
				padding: 0;

				.carousel__prev,
				.carousel__next {
					display: flex;
				}
			}

			&--thumbs {
				display: none;
			}
		}

		&__swiper {
			&--invisible {
				visibility: unset;
			}
		}

		&__hide-first {
			display: unset;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding-bottom: 0;
		border: 0;

		&__image {
			pointer-events: none;
		}
	}
}
