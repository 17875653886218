@mixin wishlist {
	@at-root .wishlist {
		@extend %position-top-right;

		font-size: 20px;
		color: $highlight-color;
		padding: $block-spacing;

		&__icon,
		&__loader {
			visibility: visible;
			width: auto;

			&--hidden {
				visibility: hidden;
				width: 0;
			}
		}

		@content;
	}
}
