@mixin title {
	@at-root .title {
		@extend %heading1;

		&--h2 {
			@extend %heading2;
		}

		&--h3 {
			@extend %heading3;
		}

		&--h4 {
			@extend %heading4;
		}

		&--h5 {
			@extend %heading5;
		}

		&--h6 {
			@extend %heading6;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--bold {
			font-weight: bold;
		}

		&--align-left {
			text-align: left;
		}

		&--align-center {
			text-align: center;
		}

		@content;
	}
}
