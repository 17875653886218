.faq {
	color: $black;
	line-height: 1.5em;

	&__question {
		font-size: 16px;
		font-family: $secondary-font-family;
		padding: $block-spacing * 4 $block-spacing * 6 $block-spacing * 4 $block-spacing * 4;
		cursor: pointer;
		border-bottom: 1px solid $border-color;
	}

	&__answer {
		font-size: 16px;
		padding: $block-spacing * 4;
		background-color: $background-color;
		font-weight: 500;
	}

	.accordion__icon-open,
	.accordion__icon-close {
		right: $block-spacing * 4;
	}

	&:first-child {
		border-top: 1px solid $border-color;
	}
}
