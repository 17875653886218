@include wishlist {
	color: $black;
	font-size: 24px;

	&--product-detail {
		top: $block-spacing * 4;
		right: $block-spacing * 4;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			right: 0;
		}
	}

	&:hover {
		color: $brand-color;
	}
}
