@include grid {
	&--vertical-spacing {
		padding-top: $vertical-spacing;
		padding-bottom: $vertical-spacing;
	}

	&--vertical-spacing-top {
		padding-top: $vertical-spacing;
	}

	&--vertical-spacing-bottom {
		padding-bottom: $vertical-spacing;
	}

	&--horizontal-spacing {
		padding-left: $horizontal-spacing;
		padding-right: $horizontal-spacing;
	}

	&--full-height {
		height: 100%;
	}

	&--border-top {
		&::before {
			content: ' ';
			position: absolute;
			top: 0;
			left: $block-spacing;
			right: $block-spacing;
			height: 1px;
			background-color: $border-color;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--vertical-spacing {
			padding-top: $vertical-spacing-mobile;
			padding-bottom: $vertical-spacing-mobile;
		}

		&--vertical-spacing-top {
			padding-top: $vertical-spacing-mobile;
		}

		&--vertical-spacing-bottom {
			padding-bottom: $vertical-spacing-mobile;
		}

		&--horizontal-spacing {
			padding-left: $horizontal-spacing-mobile;
			padding-right: $horizontal-spacing-mobile;
		}
	}

	&__col {
		&--vertical-spacing {
			padding-top: $vertical-spacing + $block-spacing;
			padding-bottom: $vertical-spacing + $block-spacing;
		}

		&--vertical-spacing-top {
			padding-top: $vertical-spacing + $block-spacing;
		}

		&--vertical-spacing-bottom {
			padding-bottom: $vertical-spacing + $block-spacing;
		}

		&--horizontal-spacing {
			padding-left: $horizontal-spacing;
			padding-right: $horizontal-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--vertical-spacing {
				padding-top: $vertical-spacing-mobile + $block-spacing;
				padding-bottom: $vertical-spacing-mobile + $block-spacing;
			}

			&--vertical-spacing-top {
				padding-top: $vertical-spacing-mobile + $block-spacing;
			}

			&--vertical-spacing-bottom {
				padding-bottom: $vertical-spacing-mobile + $block-spacing;
			}

			&--horizontal-spacing {
				padding-left: $horizontal-spacing-mobile;
				padding-right: $horizontal-spacing-mobile;
			}
		}
	}
}
