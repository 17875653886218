@include product-info {
	background-color: $white;
	padding: $block-spacing * 5 $block-spacing $block-spacing $block-spacing * 5;
	top: 152px;
	width: 33.3333%;
	height: fit-content;

	&__container {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		margin-bottom: $block-spacing * 2;
	}

	&__sizes {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-bottom: $block-spacing * 2;

		.select__dropdown--inner--hidden {
			display: none;
		}
	}

	&__size {
		display: flex;
		flex-wrap: wrap;

		.size__color--hidden {
			display: none;
		}

		&:first-child {
			padding-right: $block-spacing * 2;
			width: 50%;

			@include responsive(('desktop')) {
				width: 100%;
				margin-bottom: $block-spacing;
				padding-right: 0;
			}
		}

		&:last-child {
			width: 50%;

			@include responsive(('desktop')) {
				width: 100%;
			}
		}
	}

	&__brand {
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1.4px;
		margin-bottom: $block-spacing * 2;
	}

	&__name {
		font-size: 24px;
		line-height: 36px;
		font-family: $secondary-font-family;
		color: $black;
	}

	&__price {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		margin-top: $block-spacing;
		margin-bottom: $block-spacing;
		font-weight: bold;

		&__highlight {
			color: $brand-color;
			font-size: 16px;
		}

		&__advice {
			text-decoration: none;
			font-size: 16px;
		}

		span {
			text-decoration: line-through;
		}
	}

	&__option {
		color: $gray;
		margin: $block-spacing 0 $block-spacing * 2;
		font-size: 12px;
		text-transform: uppercase;
		width: 100%;

		span {
			font-weight: bold;
		}

		a {
			float: right;
		}
	}

	&__form {
		.text {
			font-size: 14px;
		}
	}

	&__tags {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: $block-spacing * 2;
	}

	&__tag {
		margin-right: 4px;
		background-color: $background-color-3;
		color: $white;
		font-size: 10px;
		font-weight: bold;
		text-transform: uppercase;
		padding: $block-spacing / 2 $block-spacing * 1.5;
		border-radius: 50px;
	}

	&__stock {
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		margin: $block-spacing * 2 0;

		i {
			font-size: 16px;
			margin-right: $block-spacing / 2;
		}

		&--success {
			color: $highlight-color-2;
		}

		&--warning {
			color: $warning-color;
		}

		&--error {
			color: $error-color;
		}

		&--hidden {
			display: none;
		}
	}

	&__review {
		display: flex;
		align-items: flex-end;
		font-size: 14px;
		margin-top: $block-spacing * 3;

		span {
			display: inline-block;
		}

		i {
			font-size: 22px;
			color: $highlight-color;
		}
	}

	@include responsive(('tablet-landscape')) {
		width: 50%;
		position: relative;
		top: auto;
		height: auto;
		padding-bottom: $block-spacing * 5;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding-top: $block-spacing;
		padding-left: $block-spacing;
		padding-bottom: $block-spacing * 5;
		width: 100%;
		position: relative;
		top: auto;
		height: auto;
	}

	@include responsive(('mobile')) {
		&__size {
			&:first-child {
				margin-bottom: $block-spacing;
				padding-right: 0;
			}

			&:last-child {
				padding-left: 0;
			}

			&:first-child,
			&:last-child {
				width: 100%;
			}
		}
	}
}
