@mixin usp {
	@at-root .usp {
		width: 100%;
		margin: 0;

		&__item {
			color: $font-color;
			position: relative;
			display: inline-block;
			padding: 0 $block-spacing;
			text-transform: uppercase;
			vertical-align: middle;

			strong {
				font-weight: 700;
			}

			&__image {
				display: inline-block;
				max-width: 100%;
				vertical-align: middle;
			}

			&__icon {
				margin: 0 $block-spacing * 0.5 0 0;
			}

			&--align-center {
				text-align: center;
			}
		}

		&--border-top {
			border-top: 1px solid $border-color;
		}

		&--border-bottom {
			border-bottom: 1px solid $border-color;
		}

		@content;
	}
}
