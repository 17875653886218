.popup {
	@extend %flex-column;

	flex-wrap: nowrap;
	z-index: 9;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 374px;
	border: 3px solid $background-color;
	background-color: $white;
	padding: $block-spacing * 2;
	max-width: calc(100% - #{$block-spacing * 4});
	max-height: calc(100vh - #{$block-spacing * 4});

	&__scroll {
		overflow-y: auto;
		flex: 1;
	}

	&__title {
		@extend %heading3;

		text-align: center;
		color: $black;
		font-weight: bold;
		font-family: $secondary-font-family;
	}

	&__summary {
		@extend %heading6;

		text-align: center;
		color: $black;
		font-weight: bold;
		margin-bottom: $block-spacing * 2;
		font-family: $secondary-font-family;
	}

	&__content {
		@extend %paragraph;

		color: $black;
		text-align: center;
		margin-bottom: $block-spacing * 2;
	}

	&__close {
		position: absolute;
		top: $block-spacing * 2;
		right: $block-spacing * 2;
		color: $black;
		font-size: 40px;
		cursor: pointer;
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;

		.mdc-text-field {
			width: calc(50% - #{$block-spacing / 2});
			margin-bottom: $block-spacing;

			&:last-child {
				width: 100%;
			}
		}
	}

	.button {
		margin-top: $block-spacing;
	}

	&--hidden {
		display: none;
	}
}
