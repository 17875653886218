@include text {
	color: $black;
	font-weight: 500;
	font-size: 16px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: inherit;
		font-family: $secondary-font-family;
	}

	h1 {
		@extend %heading4;
	}

	h2 {
		@extend %heading4;
	}

	h3 {
		@extend %heading4;
	}

	h4 {
		@extend %heading4;
	}

	h5 {
		@extend %heading5;
	}

	h6 {
		@extend %heading6;
	}

	td,
	th {
		text-align: left;
	}

	img {
		display: inline-block;
	}

	a {
		color: $brand-color;
	}

	&--small {
		font-size: 14px;
	}

	&--spacing {
		margin-top: $block-spacing * 4;
		margin-bottom: $block-spacing * 4;
	}

	&--spacing-top {
		margin-top: $block-spacing * 4;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing * 4;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}
	}

	&__article {
		img {
			border-radius: 50px;
		}
	}

	&__read-more {
		overflow: hidden;

		&::after {
			position: absolute;
			display: none;
			content: ' ';
			bottom: 0;
			left: 0;
			right: 0;
			height: 212px;
			background-image: linear-gradient(to top, $white 63px, rgba($white, 0.5));
			pointer-events: none;
		}

		.button {
			min-width: 220px;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background-color: $white;
			display: none;
		}

		&--hidden {
			max-height: 360px;
			display: block;

			&::after {
				display: block;
			}

			.button {
				display: block;
			}
		}

		&--shop.text__read-more--hidden {
			height: 46px;
			padding-right: 100px;

			.text__toggle {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: $block-spacing;
				font-size: 14px;
				color: $brand-color;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}

			&::after {
				display: none;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		font-size: 14px;
	}
}
