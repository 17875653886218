.product-specs {
	margin-top: -$block-spacing * 3;
	background-color: $white;
	color: $black;
	font-size: 18px;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;

	&__heading {
		border-top: 1px solid $background-color;
		padding: $block-spacing * 4 $block-spacing * 5 $block-spacing * 4 $block-spacing * 4;
		font-family: $secondary-font-family;
	}

	&__content {
		font-size: 16px;
		line-height: 1.5em;
		padding: $block-spacing * 4;
		background-color: $background-color;

		table {
			width: 100% !important;

			td,
			th {
				padding-right: $block-spacing;

				&:last-child {
					padding-right: 0;
					padding-left: $block-spacing;
					text-align: right !important;
				}
			}
		}

		a {
			text-decoration: none;
			color: $brand-color;

			&:hover {
				text-decoration: underline;
			}
		}

		ul {
			list-style: unset;
			padding-left: 16px;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			padding-right: 8.3333%;
		}
	}

	.accordion__icon-open,
	.accordion__icon-close {
		right: $block-spacing * 4;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		border-right: 0;
	}
}
