.shop-container {
	width: 75%;
	border-left: 1px solid $border-color;
	padding-left: 8px;
	padding-top: $block-spacing * 3;

	.title {
		padding: 0 $block-spacing;
	}

	.text {
		padding: 0 $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		padding-left: 0;
		border: 0;
		padding-top: 0;
	}
}
