@include color {
	z-index: 1;

	&__replacement {
		margin: 0 $block-spacing $block-spacing 0;
		height: 56px;
		width: 56px;
		border-radius: 56px;
		border: 1px solid $border-color;
		box-shadow: none;

		&::before {
			display: block;
			content: ' ';
			height: 64px;
			width: 64px;
			border-radius: 64px;
			background-color: $white;
			border: 1px solid transparent;
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			border: 1px solid $border-color;
			box-shadow: none;
		}

		&--color {
			margin: 4px 12px 12px 4px;
		}
	}

	&--filters .color__replacement {
		margin: $block-spacing / 2 $block-spacing $block-spacing / 2 $block-spacing;
	}

	&__input:checked + .color__replacement {
		border: 1px solid $border-color;
		box-shadow: none;

		&::before {
			border: 1px solid $brand-color;
		}
	}
}
