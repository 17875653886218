.accordion {
	&__toggle {
		cursor: pointer;
		position: relative;
	}

	&__icon-close,
	&__icon-open {
		@extend %position-right;

		&--top-right {
			top: 0;
			right: $block-spacing;
			font-size: 14px;
			color: $brand-color;
			transform: unset;
			line-height: 48px;
		}
	}

	&__icon-close {
		display: block;
	}

	&__icon-open {
		display: none;
	}

	&__container {
		overflow-y: hidden;
		transition: height 0.3s ease-in-out 0s;
	}

	&__summary {
		display: none;
	}

	&--border {
		border: 1px solid $border-color;
		border-bottom: 0;
		padding: $block-spacing;

		&:last-child {
			border-bottom: 1px solid $border-color;
		}
	}

	&--hidden {
		.accordion__container {
			height: 0;
		}

		.accordion__icon-close {
			display: none;
		}

		.accordion__icon-open {
			display: block;
		}

		.accordion__summary {
			display: block;
		}
	}

	&__sub {
		padding: $block-spacing 0;
	}
}
