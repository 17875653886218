.price-filter {
	width: 100%;
	padding: 0 $block-spacing * 2;

	&__slider {
		width: 100%;
		margin: $block-spacing 0;
		height: 2px;
		box-shadow: none;
		background: $black;
		border-radius: 10px;
		border: 1px solid $black;

		.noUi-base,
		.noUi-connects {
			width: 100%;
			height: 100%;
			position: relative;
		}

		.noUi-handle {
			border-radius: 50px;
			width: $block-spacing * 3;
			height: $block-spacing * 3;
			top: -12px;
			box-shadow: none;
			background: $active-color;
			border: none;

			&::before,
			&::after {
				display: none;
			}
		}
	}

	&__container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-top: $block-spacing * 4;
	}

	&__input {
		width: 33%;
		height: $block-spacing * 5;
		border: 1px solid $border-color;
		border-radius: 5px;
		text-align: center;
	}

	&__seperator {
		font-size: 20px;
		font-weight: 600;
		letter-spacing: -5px;
		text-align: center;
	}
}
