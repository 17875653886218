.size-guide {
	&__open {
		font-size: 14px;
		color: $black;
		font-weight: bold;
		text-decoration: underline;
		letter-spacing: 0.8px;
		cursor: pointer;

		i {
			margin-right: 8px;
		}
	}

	&__scroll {
		overflow-y: auto;
		height: 100%;
	}

	&__dropdown {
		position: fixed;
		top: 0;
		right: 0;
		height: 100vh;
		width: 400px;
		max-width: 100vw;
		background-color: $white;
		z-index: 9;
		border: 3px solid $background-color;
		display: flex;
		flex-direction: column;
	}

	&__close {
		font-size: 40px;
		color: $black;
		top: $block-spacing * 2;
		right: $block-spacing * 2;
		cursor: pointer;
		position: absolute;
	}

	&__title {
		font-size: 18px;
		color: $black;
		font-family: $secondary-font-family;
		padding: $block-spacing * 4 $block-spacing * 2;
	}

	&__heading {
		font-size: 16px;
		color: $black;
		font-weight: bold;
		padding: 0 $block-spacing * 2;

		i {
			margin-right: 12px;
		}
	}

	&__content {
		font-size: 14px;
		line-height: 1.5em;
		color: $black;
		flex: 1;
		padding: $block-spacing * 2;
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($black, 0.4);
		z-index: 9;
	}

	&--hidden {
		.size-guide__overlay,
		.size-guide__dropdown {
			display: none;
		}
	}
}
