@mixin pagination {
	@at-root .pagination {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		padding: $block-spacing calc(#{$block-spacing} / 2);

		&__page {
			@extend %paragraph;
			@extend %flex-row;

			transition: all 0.3s ease-in-out 0s;
			align-items: center;
			justify-content: center;
			margin: calc(#{$block-spacing} / 2);
			color: $black;
			background-color: $background-color;
			height: 40px;
			min-width: 40px;

			&:hover {
				background-color: $hover-color;
				color: $white;
			}

			&--active {
				background-color: $active-color;
				color: $white;
			}

			&--prev,
			&--next {
				@extend %heading6;
			}
		}

		@content;
	}
}
