@include terms-and-conditions {
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: $block-spacing * 2 0 $block-spacing * 3;

	&__item {
		padding: $block-spacing;
		position: relative;
		font-size: 12px;
		margin-right: $block-spacing;

		&:first-child {
			font-weight: bold;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		padding: $block-spacing * 2 0;
		margin-bottom: $block-spacing * 2;
		border-bottom: 1px solid $border-color;
		justify-content: center;
	}
}
