@include usp {
	display: flex;
	align-items: center;
	justify-content: center;

	&__item {
		font-size: 12px;
		color: $black;
		font-weight: bold;
		padding: $block-spacing;
		margin-right: $block-spacing * 2;
		text-transform: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		font-size: 16px;
		margin-right: $block-spacing / 2;
	}

	&--footer {
		margin-top: $block-spacing * 2;
	}

	&--header {
		.usp__item {
			font-weight: normal;
		}

		.usp__icon {
			color: $highlight-color;
			font-size: 16px;
		}
	}

	&--mobile {
		display: none;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__item {
			width: 100%;
			text-align: center;
			padding: 0;
		}

		&--desktop {
			display: none;
		}

		&--mobile {
			display: block;
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&__item {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
