@mixin text {
	@at-root .text {
		@extend %paragraph;

		position: relative;
		width: 100%;

		strong,
		b {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ol {
			@extend %ordered-list;
		}

		ul {
			@extend %unordered-list;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		iframe {
			max-width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $brand-color;
		}

		h1 {
			@extend %heading1;
		}

		h2 {
			@extend %heading2;
		}

		h3 {
			@extend %heading3;
		}

		h4 {
			@extend %heading4;
		}

		h5 {
			@extend %heading5;
		}

		h6 {
			@extend %heading6;
		}

		a {
			text-decoration: none;
			color: $highlight-color;

			&:hover {
				text-decoration: underline;
			}
		}

		&--align-left {
			text-align: left;
		}

		&--align-right {
			text-align: right;
		}

		&--align-center {
			text-align: center;
		}

		&--bold {
			font-weight: bold;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--small {
			@extend %small;
		}

		@content;
	}
}
