@include nav {
	justify-content: space-between;
	align-items: center;
	padding: 0 $horizontal-spacing;

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		padding: $block-spacing * 1.5 0;
	}

	&__item {
		color: $black;
		font-weight: 600;
		text-transform: uppercase;
		padding: 0 $block-spacing;
		font-size: 14px;
		display: flex;
		align-items: stretch;
		white-space: nowrap;

		&:hover {
			text-decoration: none;

			.nav__sub {
				display: block;
			}
		}

		&--active {
			font-weight: bold;
			color: $brand-color;
		}

		&--mobile {
			display: none;
		}

		&--active,
		&:hover {
			.nav__link {
				&::after {
					content: ' ';
					display: block;
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 100%;
					height: 3px;
					background-color: $brand-color;
				}
			}
		}

		&--relative {
			position: relative;
		}
	}

	&__icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 22px;
		height: 50px;
		min-width: 50px;
		margin: 0;
		cursor: pointer;
		border: 1px solid $background-color-2;
		border-radius: 50px;
		margin-left: $block-spacing * 2;
		position: relative;

		&--mobile {
			display: none;
		}

		&--cart {
			padding: 0 $block-spacing * 2;
		}

		&:hover {
			border-color: $highlight-color;
		}
	}

	&__count {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		min-width: 18px;
		height: 18px;
		border-radius: 18px;
		text-align: center;
		line-height: 18px;
		color: $white;
		font-size: 10px;
		padding: 0 4px;
		background-color: $brand-color;
	}

	&__total {
		font-size: 14px;
		padding-left: 8px;
	}

	&__left {
		display: flex;
		flex: 1;
		align-items: stretch;
		justify-content: flex-start;

		.nav__item {
			padding-right: $block-spacing * 3;
		}
	}

	&__right {
		display: flex;
		flex: 1;
		align-items: stretch;
		justify-content: flex-end;

		.nav__item {
			color: $brand-color;
			padding-left: $block-spacing * 3;
		}
	}

	&__container {
		padding: 0;

		&--left {
			align-items: stretch;
			flex: 1;

			.nav__item {
				display: flex;
				align-items: stretch;
				justify-content: center;
				padding: 0 $block-spacing * 3 0 0;
			}
		}

		&--right {
			flex: 1;

			.nav__item {
				padding: 0 0 0 $block-spacing * 4;

				&:last-child {
					margin-right: $block-spacing * 2;
				}
			}
		}

		&--full-width {
			width: 100%;
			align-items: stretch;
			justify-content: space-between;
		}
	}

	&__sub {
		background-color: $white;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 9;
		display: none;
		border-top: 2px solid $border-color;
		max-height: calc(100vh - 152px);
		overflow-y: auto;
		border-bottom: 2px solid $border-color;

		&--small {
			width: auto;
			right: auto;
			left: -$block-spacing * 3;

			.nav__sub-items {
				width: 100%;
				padding-left: $block-spacing * 3;
				padding-right: $block-spacing * 3;
			}
		}
	}

	&__sub-container {
		width: $grid-width;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: $block-spacing * 2 $vertical-spacing;
	}

	&__sub-items {
		width: 20%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding: $block-spacing;
		position: relative;

		&::before {
			content: ' ';
			display: block;
			width: 1px;
			height: calc(100% - #{$block-spacing * 8});
			position: absolute;
			left: -$block-spacing;
			top: $block-spacing * 4;
			background-color: $border-color;
		}

		&:first-child,
		&:nth-child(5n + 6) {
			&::before {
				display: none;
			}
		}

		&:only-child {
			border: 0;
		}
	}

	&__sub-item {
		font-size: 14px;
		display: block;
		width: 100%;
		color: $black;
		padding: $block-spacing * 2 $block-spacing * 2 $block-spacing * 2 $block-spacing;
		position: relative;

		&--heading {
			font-family: $secondary-font-family;
		}

		&:hover,
		&--active {
			color: $brand-color;
		}
	}

	&__mobile-overlay {
		display: none;
		position: fixed;
		top: 32px;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $background-color;
	}

	&__mobile-dropdown {
		display: none;
		position: fixed;
		z-index: 9;
		background-color: $white;
		top: 32px;
		left: 0;
		right: 0;
		bottom: 0;
		border-radius: 50px 0 0;

		&--active {
			display: block;

			+ .nav__mobile-overlay {
				display: block;
			}
		}
	}

	&__mobile-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&--hidden {
			display: none;
		}
	}

	&__mobile-scroll {
		flex: 1;
		overflow-y: auto;
		padding: 0 $block-spacing * 5;
	}

	&__mobile-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $block-spacing * 4 $block-spacing * 5;
		position: relative;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			width: calc(100% - #{$block-spacing * 10});
			height: 1px;
			background-color: $border-color;
			top: 100%;
			left: $block-spacing * 5;
		}
	}

	&__mobile-title {
		font-size: 18px;
		color: $black;
		font-family: $secondary-font-family;
	}

	&__mobile-link {
		padding: $block-spacing * 4 0;
		flex: 1;
	}

	&__mobile-icon {
		padding: $block-spacing * 4;
		font-size: 18px;
		cursor: pointer;
	}

	&__mobile-item {
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $black;
		font-family: $secondary-font-family;
		border-bottom: 1px solid $border-color;

		&--sub {
			font-size: 14px;
			font-family: $primary-font-family;
			font-weight: normal;
			width: 50%;
			border: 0;

			.nav__mobile-link {
				padding: $block-spacing * 2 0;
			}

			&:nth-child(even) {
				padding-left: $block-spacing;
			}

			&:nth-child(odd) {
				padding-right: $block-spacing;
			}
		}

		&--small {
			font-family: $primary-font-family;
			font-size: 12px;

			.nav__mobile-link {
				padding: $block-spacing * 2.5 0;
			}
		}

		&--highlight {
			color: $brand-color;
		}

		&--active {
			text-decoration: underline;
		}
	}

	&__mobile-subs {
		display: flex;
		flex-wrap: wrap;
	}

	&__mobile-toggle,
	&__mobile-close,
	&__mobile-back {
		display: flex;
		align-items: center;
		font-size: 24px;
		color: $black;
		padding-left: $block-spacing;
		height: 100%;
		cursor: pointer;

		span {
			margin-left: $block-spacing;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	&__mobile {
		display: none;
		align-items: center;
		justify-content: flex-start;
		padding-right: $block-spacing * 2;
	}

	@include responsive(('tablet-landscape', 'desktop')) {
		&__left {
			width: 100%;
			order: 2;
		}

		&__right {
			width: 100%;
			order: 1;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		padding: 0 $horizontal-spacing-mobile;

		&__mobile {
			display: flex;
		}

		&__open-search {
			display: flex;
		}

		&__count {
			display: block;
			font-size: 9px;
			min-width: 18px;
			height: 18px;
			font-weight: bold;
		}

		&__total {
			display: none;
		}

		&__container--full-width {
			display: none;
		}

		&__container--left {
			flex: 0;
		}

		&__icon {
			border: 0;
			font-size: 22px;
			height: 38px;
			min-width: unset;
			padding-right: $block-spacing;
			margin-left: $block-spacing;

			&--cart {
				padding: 0;
				padding-right: $block-spacing;
			}
		}

		&__icon--desktop {
			display: none;
		}

		&__icon--mobile {
			display: flex;
		}

		&__container--right {
			padding-left: 0;

			.nav__item {
				padding-left: $block-spacing * 2;

				&:last-child {
					margin-right: $block-spacing;
				}
			}
		}
	}

	@include responsive(('mobile')) {
		&__container {
			flex-flow: row nowrap;
		}

		&__icon {
			margin-left: 0;
		}
	}
}
