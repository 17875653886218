.set-info {
	background-color: $white;
	padding: $block-spacing * 4;

	&__name {
		@extend %heading3;

		font-family: $secondary-font-family;
		color: $black;
	}

	&__summary {
		@extend %small;

		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
	}

	&__description {
		@extend %paragraph;

		color: $black;
		margin-bottom: $block-spacing * 2;
	}

	&__price {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		padding-bottom: $block-spacing * 2;
		border-bottom: 1px solid $border-color;

		&__highlight {
			font-size: 22px;
			font-weight: bold;
			color: $highlight-color;
		}

		&__advice {
			font-size: 14px;
			text-decoration: none;
		}

		span {
			text-decoration: line-through;
			font-weight: bold;
		}
	}

	&__container {
		@extend %flex-row;

		flex: 1 1;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $block-spacing * 2;
	}

	&__product {
		display: flex;
		flex-direction: column;
		margin-top: $block-spacing * 2;
	}

	&__product-image {
		width: 80px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border: 1px solid $border-color;

		&::after {
			padding-bottom: 140%;
			content: ' ';
			display: block;
		}
	}

	&__product-info {
		flex: 1;
		padding-left: $block-spacing * 2;

		.set-info__price {
			border-bottom: 0;
			margin: 0;
		}
	}

	&__product-name {
		font-size: 16px;
		color: $black;
		margin-bottom: $block-spacing;
	}

	.size-select__dropdown {
		margin-bottom: 0;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		padding-left: 8.3333%;
		padding-right: 8.3333%;
	}
}
