@mixin form {
	@at-root .form {
		margin: 0;
		width: 100%;

		&--inline {
			width: auto;
		}

		@content;
	}
}
