@include footer {
	align-items: center;
	justify-content: center;
	padding: 0;
	background-color: $white;
	border-radius: 0 0 50px 50px;
	margin-top: 0;

	&__top,
	&__mid,
	&__bot {
		padding: 0 $horizontal-spacing;
	}

	&__top {
		width: 100%;
		background-color: $background-color;
		padding-top: $vertical-spacing;
		padding-bottom: $vertical-spacing;
	}

	&__mid {
		border-top: 1px solid rgba($white, 0.2);
		width: 100%;
		background-color: rgba($background-color-2, 0.26);
	}

	&__bot {
		@extend %flex-row;

		align-items: center;
		background-color: $white;
		width: 100%;
		border-radius: 0 0 50px 50px;
	}

	&__contact {
		width: calc(33.3333% - #{$block-spacing * 2});
		margin: $block-spacing;
		border: 1px solid transparent;
		flex: 0 1 auto;
	}

	&__subtitle {
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.4px;
		margin-top: $block-spacing * 3;
		font-weight: 400;

		&--center {
			text-align: center;
		}
	}

	&__title {
		font-family: $secondary-font-family;
		font-size: 40px;
		margin-top: $block-spacing;
		margin-bottom: $block-spacing * 3;

		&--center {
			text-align: center;
		}
	}

	&__text {
		font-size: 16px;
		line-height: 1.5em;

		&--small {
			font-size: 14px;
		}

		table {
			text-align: left;
		}
	}

	&__image {
		width: calc(33.3333% - #{$block-spacing * 2});
		margin: $block-spacing;
		border-radius: 50px;
		border: 1px solid transparent;
		flex: 0 1 auto;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&--shoppingcart {
		.footer__top,
		.footer__mid {
			display: none;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__contact {
			width: calc(100% - #{$block-spacing * 2});
			text-align: center;
		}

		&__text {
			table {
				margin: 0 auto;
			}
		}

		&__image {
			width: 320px;
			height: 250px;
			margin: 0 auto $block-spacing * 3;
		}

		&__mid {
			border: 0;
		}

		&__top,
		&__mid,
		&__bot {
			padding: 0 $horizontal-spacing-mobile;
		}

		&__bot {
			.grid__col {
				width: 100%;
			}
		}

		&__review {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: $block-spacing * 2;

			span,
			div {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}
