// @mixin shoppingcart-register {
// 	@at-root .shoppingcart-register {
// 		&__type {
// 			display: none;

// 			&--active {
// 				display: block;
// 			}
// 		}

// 		@content;
// 	}
// }

body {
	.shoppingcart__register {
		padding-right: $block-spacing * 2;
		border-right: 1px solid rgb(0 0 0 / 20%);

		@include responsive('mobile') {
			padding-right: unset;
			border-right: unset;
		}
	}

	.shoppingcart__login {
		padding-left: $block-spacing * 2;

		@include responsive('mobile') {
			padding-left: unset;
		}
	}
}
