@include logo {
	width: 295px;
	margin: $block-spacing * 2 $block-spacing $block-spacing;

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 132px;
		height: 22px;
		margin: $block-spacing * 1.5 $block-spacing;
		margin-right: 0;
	}

	@include responsive(('mobile')) {
		width: auto;
		flex: 1 1 auto;
		margin-left: 0;
		margin-right: $block-spacing;
	}
}
