.shoppingcart-alert {
	border: 1px solid $brand-color;
	color: $brand-color;
	background-color: $background-color;
	padding: 10px;
	font-size: 14px;
	text-align: center;
	width: 100%;
	margin-top: $block-spacing * 2;

	a {
		text-decoration: underline;
	}

	strong {
		font-weight: bold;
	}

	&--spacing-top {
		margin-top: $block-spacing;
	}
}
