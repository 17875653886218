.offices {
	display: flex;
	flex-wrap: wrap;
	align-items: top;
	justify-content: center;
	width: 100%;
	margin-top: -12px;

	&__section {
		width: 33.3333%;
		padding: 60px $block-spacing;
		border-left: 1px solid $border-color;
		text-align: center;
		font-size: 16px;
		line-height: 1.5em;
		font-weight: 500;

		strong {
			font-weight: bold;
			font-size: 14px;
			letter-spacing: 1.4px;
		}

		a {
			color: $brand-color;
			text-decoration: underline;
		}

		&:first-child {
			border: 0;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__section {
			width: 100%;
			border: 0;
			padding: $block-spacing $block-spacing * 2;

			&:first-child {
				padding-top: $block-spacing * 4;
			}
		}
	}
}
