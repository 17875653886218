.newsletter {
	width: calc(33.3333% - #{$block-spacing * 2});
	margin: $block-spacing;
	border: 1px solid $white;
	border-radius: 50px;
	padding: 0 $block-spacing * 4;
	flex: 0 1 auto;
	background-color: $white;
	padding-bottom: $block-spacing * 4;

	.mdc-text-field {
		margin-bottom: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: calc(100% - #{$block-spacing * 2});
		margin-bottom: $block-spacing * 4;
	}
}
