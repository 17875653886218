@include shoppingcart-table {
	color: $black;

	&__image-placeholder {
		display: block;

		&::after {
			padding-bottom: 100%;
		}
	}

	&__cell {
		padding-right: $block-spacing;

		&:last-child {
			padding-right: 0;
		}
	}

	&__price {
		display: flex;
		flex-flow: column wrap;
		align-items: flex-end;
		color: $brand-color;

		s {
			color: $black;
		}
	}

	&__delete {
		&:hover {
			color: $brand-color;
		}
	}

	&__delivery {
		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}
}
