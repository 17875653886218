@mixin active-filters {
	@at-root .active-filters {
		@extend %flex-row;

		width: 100%;
		padding: #{$block-spacing / 2} 0;

		&__clear {
			@extend %button;
			@extend %paragraph;

			text-align: left;
			padding: #{$block-spacing / 2} 0;

			&:hover {
				color: $hover-color;
			}
		}

		&__label {
			display: block;
			width: 100%;
			margin: #{$block-spacing / 2} 0;
		}

		&__input {
			display: none;
		}

		&__replacement {
			@extend %paragraph;

			display: none;
			position: relative;
			cursor: pointer;
			padding: $block-spacing;
			padding-right: #{$block-spacing * 3};
			border: 1px solid $border-color;

			i {
				@extend %position-right;

				right: $block-spacing;
			}

			&:hover {
				background: $background-color;
			}
		}

		&__input:checked + .active-filters__replacement {
			display: block;
		}

		@content;
	}
}
